html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #001100; 
}
.main-container {
  min-height: 100vh; 
  background-color: #001100; 
}

.custom-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.custom-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.textarea-no-scrollbar {
  overflow: auto; /* Enable scrolling */
  scrollbar-width: none; /* For Firefox */
}

.textarea-no-scrollbar::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}
.textarea-no-scrollbar:focus {
  border-color: #00FF47 !important; /* Use !important to override any other styles */
}
.button-active:active {
box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
transform: translateY(2px);
}